import React, { useEffect, useState } from "react";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../loader/CircleLoade";
import { Link } from "react-router-dom";

export default function EnrollmentReport() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    getReportData(
      {
        mobile: "",
        status: "",
        startDate: "",
        endDate: "",
      },
      1
    );
  }, []);

  const searchFormik = useFormik({
    initialValues: {
      mobile: "",
      status: "",
      startDate: "",
      endDate: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.mobile) {
        if (data.mobile.length < 10) {
          errors.mobile = "Mobile must be in 10 digits.";
        }
      }
      if (data.startDate === "" && data.endDate !== "") {
        errors.startDate = "Please select date";
      }
      if (data.startDate !== "" && data.endDate === "") {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "End date should be greater than start date";
      }
      // if (data.startDate && data.endDate) {
      //     const maxEndDate = new Date(data.startDate)
      //     maxEndDate.setDate(maxEndDate.getDate() + 30) // Adjust the range as needed (e.g., 31 days)

      //     if (data.endDate > maxEndDate) {
      //         errors.endDate = 'End date should be within 31 days from the start date.'
      //     }
      // }
      return errors;
    },
    onSubmit: (data) => {
      setCurrentPage(1);
      getReportData(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const getReportData = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      // const searchMobile = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      // const searchStatus = data.status === "" ? "" : `&status=${data.status}`;

      const searchMobile =
        data?.mobile !== undefined && data?.mobile !== ""
          ? `&mobile=${data?.mobile}`
          : "";
      const searchStatus =
        data?.status !== undefined && data?.status !== ""
          ? `&status=${data?.status}`
          : "";

      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      await axios
        .get(
          Constants.baseUrl +
            `reports/users/list?userType=MEMBER${searchMobile}${searchStatus}${stDate}${endDate}&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${pageNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setReportData(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setReportData(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const download = (data) => {
    setLoading(true);
    setDownloadLoading(true);
    try {
      const searchMobile = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      const searchStatus = data.status === "" ? "" : `&status=${data.status}`;
      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      axios
        .get(
          Constants.baseUrl +
            `reports/download/userreport?userType=MEMBER${searchMobile}${searchStatus}${stDate}${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
            responseType: "blob", // Set the responseType to 'blob'
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            const fileData = new Blob([res.data], { type: "text/csv" });
            saveAs(fileData, "enrollmentReport.csv");
            setDownloadLoading(false);
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
            setDownloadLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  };

  const areAllValuesBlank = () => {
    const { mobile, status, startDate, endDate } = searchFormik.values;
    return !mobile && !status && !startDate && !endDate;
  };
  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section class="page-content--bge55">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <h3 class="head1">Enrollment Report</h3>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <form onSubmit={searchFormik.handleSubmit}>
                <div class="row">
                  <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                    <div class="row">
                      <div class="col-md-3 col-sm-12 col-xs-12">
                        <div class="form-group">
                          <input
                            class="form-control reset"
                            type="text"
                            placeholder="Mobile No."
                            id="mobile"
                            value={searchFormik.values.mobile}
                            onChange={(e) => {
                              const numericValue = e.target.value
                                .replace(/\D/g, "")
                                .slice(0, 10);
                              searchFormik.handleChange({
                                target: {
                                  name: "mobile",
                                  value: numericValue,
                                },
                              });
                            }}
                          />
                          {getSearchFormErrorMessage("mobile")}
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <DatePicker
                          id="startDate"
                          selected={searchFormik.values.startDate}
                          onChange={(date) =>
                            searchFormik.setFieldValue("startDate", date)
                          }
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          showYearDropdown
                          showMonthDropdown
                          autoComplete="off"
                          scrollableYearDropdown
                          maxDate={new Date()}
                          onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                          onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                          onClick={(e) => e.preventDefault()} // Preven
                          placeholderText="From date"
                        />
                        {getSearchFormErrorMessage("startDate")}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <DatePicker
                          id="endDate"
                          selected={searchFormik.values.endDate}
                          onChange={(date) =>
                            searchFormik.setFieldValue("endDate", date)
                          }
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          showYearDropdown
                          showMonthDropdown
                          autoComplete="off"
                          scrollableYearDropdown
                          maxDate={new Date()}
                          onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                          onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                          onClick={(e) => e.preventDefault()} // Preven
                          placeholderText="To date"
                        />
                        {getSearchFormErrorMessage("endDate")}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <select
                          className="form-control"
                          id="status"
                          value={searchFormik.values.status}
                          onChange={searchFormik.handleChange}
                        >
                          <option value="">Select Status</option>
                          <option value="APPROVED">Approved</option>
                          <option value="REJECT">Rejected</option>
                          <option value="Verified">Pending for approval</option>
                          <option value="NEW">New Plumber</option>
                        </select>
                        {getSearchFormErrorMessage("status")}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <div class="d-flex justify-content-between">
                      <button
                        type="submit"
                        class="btn btn-primary report-submit-button"
                        //disabled={areAllValuesBlank()}
                      >
                        Search
                      </button>
                      
                      <button
                        type="button"
                        className="btn btn-primary report-submit-button"
                        onClick={() => {
                          setFilterData({});
                          setCurrentPage(1);
                          searchFormik.resetForm();
                          getReportData(
                            {
                              mobile: "",
                              status: "",
                              startDate: "",
                              endDate: "",
                            },
                            1
                          );
                        }}
                      >
                        Reset
                      </button>

                      {reportData === null ? (
                        ""
                      ) : (

                        <Link
                          to="#"
                          class={`btn btn-primary report-submit-button mx-5 ${
                            downloadLoading ? "btn-opacity" : ""
                          }`}
                          onClick={() => download(searchFormik.values)}
                        >
                          {downloadLoading ? (
                            <span>
                              {" "}
                              <i class="fa fa-spinner fa-spin"></i>Export
                            </span>
                          ) : (
                            " Export"
                          )}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </form>
              <div class="row mt-20">
                <div class="col-md-12">
                  <div class="table-responsive table--no-card">
                    <table class="table table-borderless table-striped table-earning">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          {/* <th>Member Unique ID</th>
                          <th>ZM Name</th>
                          <th>BM Name</th>
                          <th>ASM Name</th>
                          <th>FS Name</th>
                          <th>FS Number</th>
                          <th>Plumber Name</th>
                          <th>Mobile Number</th>
                          <th>Email ID</th>
                          <th>Address1</th>
                          <th>Address2</th>
                          <th>Address3</th>
                          <th>City</th>
                          <th>District</th>
                          <th>Branch</th>
                          <th>State</th>
                          <th>Zone Name</th>
                          <th>Month</th>
                          <th>Member Profile</th>
                          <th>Date of Registration</th>
                          <th>Registration Type</th>
                          <th>Status</th>
                          <th>Reason for Rejection</th>
                          <th>Admin Approval Date for Image</th>
                          <th>Verification Approved Date by FS</th>
                          <th>Total Earned Points</th>
                          <th>Monthly Bonus</th>
                          <th>Quarterly Bonus</th>
                          <th>Total Redeemed Points</th>
                          <th>Total Balance Points</th>
                          <th>First Scan Date</th>
                          <th>Aadhaar Number</th>
                          <th>Mobile Number Linked with Aadhaar</th>
                          <th>Aadhaar Front Image</th>
                          <th>Aadhaar back Image</th>
                          <th>Pan Card Number</th>
                          <th>Pan Card Image</th>
                          <th>GST Number</th>
                          <th>GST Image</th>
                          <th>Plumber Profile</th> */}

                          <th>userUniqueid</th>
                          <th>plumberName</th>
                          <th>plumberMobile</th>
                          <th>plumberEmail</th>
                          <th>fsZone</th>
                          <th>fsbranch</th>
                          <th>fsName</th>
                          <th>fsCode</th>
                          <th>fsMobile</th>
                          <th>memberProfile</th>
                          <th>activeStatus</th>
                          <th>userWorkAddress1</th>
                          <th>userWorkAddress2</th>
                          <th>userWorkAddress3</th>
                          <th>userWorkCity</th>
                          <th>userWorkDistrict</th>
                          <th>PINCode</th>
                          <th>userWorkState</th>
                          <th>userZone</th>
                          <th>registrationDate</th>
                          <th>registerFrom</th>
                          <th>userStatus</th>
                          <th>approvalRejectedReason</th>
                          <th>adminApprovalRejectedDate</th>
                          <th>verificationApprovalRejectedDate</th>
                          <th>totalEarnedPoints</th>
                          <th>totalMonthlyBonus</th>
                          <th>totalRedeemedPoints</th>
                          <th>balancePoints</th>
                          <th>HoldPoints</th>
                          <th>quaterlyBonus</th>
                          <th>tdsPoints</th>
                          <th>basePoints</th>
                          <th>FirstScanDate</th>
                          <th>aadharNumber</th>
                          <th>aadharMobile</th>
                          <th>aadharFrontUrl</th>
                          <th>aadharBackUrl</th>
                          <th>panNumber</th>
                          <th>panFrontUrl</th>
                          <th>gstNumber</th>
                          <th>gstImage</th>
                          <th>profilePic</th>
                          <th>blockedstatus</th>
                          <th>userBranch</th>
                        </tr>
                      </thead>
                      {reportData === null ? (
                        <tbody>
                          <tr>
                            <td
                              colSpan="45"
                              style={{ textAlign: "center", fontSize: "16px" }}
                            >
                              No record found!
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {reportData &&
                            reportData.map((item, index) => {
                              const serialNumber =
                                (currentPage - 1) * limit + index + 1;
                              // const dateOfReg = item[12]
                              //   ? moment(item[12]).format("DD-MM-YYYY")
                              //   : "";
                              // const approvalDate = item[16]
                              //   ? moment(item[16]).format("DD-MM-YYYY")
                              //   : "";
                              // const verifiDate = item[17]
                              //   ? moment(item[17]).format("DD-MM-YYYY")
                              //   : "";
                              // const scanDate = item[22]
                              //   ? moment(item[22]).format("DD-MM-YYYY")
                              //   : "";
                              const isAadharFrontUrlAvailable =
                                item[35] && item[35].trim() !== "";
                              const isAadharBackUrlAvailable =
                                item[36] && item[36].trim() !== "";
                              const isPanUrlAvailable =
                                item[39] && item[39].trim() !== "";
                              const isGstUrlAvailable =
                                item[40] && item[40].trim() !== "";
                              const memberProfile =
                                item[41] && item[41].trim() !== "";
                              return (
                                <>
                                  <tr>
                                    <td>{serialNumber}</td>
                                    <td>{item[0]}</td>
                                    <td>{item[1]}</td>
                                    <td>{item[2]}</td>
                                    <td>{item[3]}</td>
                                    <td>{item[4]}</td>
                                    <td>{item[5]}</td>
                                    <td>{item[6]}</td>
                                    <td>{item[52]}</td>
                                    <td>{item[7]}</td>
                                    <td>{item[8]}</td>
                                    <td>{item[9]}</td>
                                    <td>{item[10]}</td>
                                    <td>{item[11]}</td>
                                    <td>{item[12]}</td>
                                    <td>{item[13]}</td>
                                    <td>{item[14]}</td>
                                    <td>{item[15]}</td>
                                    <td>{item[16]}</td>
                                    <td>{item[17]}</td>
                                    <td>{item[18]}</td>
                                    <td>{item[19]}</td>
                                    <td>{item[20]}</td>
                                    <td>{item[21]}</td>
                                    <td>{item[22]}</td>
                                    <td>{item[23]}</td>
                                    <td>{item[24]}</td>
                                    <td>{item[25]}</td>
                                    <td>{item[26]}</td>
                                    <td>{item[27]}</td>
                                    <td>{item[28]}</td>
                                    <td>{item[29]}</td>
                                    <td>{item[30]}</td>
                                    <td>{item[31]}</td>
                                    <td>{item[32]}</td>
                                    <td>{item[33]}</td>
                                    <td>{item[34]}</td>
                                    <td>
                                   {
                                    item[35] ? 
                                        <Link
                                          to={item[35]}
                                          target="_blank"
                                          className="select"
                                        >
                                          View
                                        </Link> : 
                                        'N/A'

                                   }
                        
                                    </td>
                                    <td>
                                        {item[36] ? 
                                        <Link
                                          to={item[36]}
                                          target="_blank"
                                          className="select"
                                        >
                                          View
                                        </Link> :
                                        'N/A'}
                                    </td>
                                    <td>{item[37]}</td>
                                    <td>
                                      { item[38] ? 
                                        <Link
                                          to={item[38]}
                                          target="_blank"
                                          className="select"
                                        >
                                          View
                                        </Link> :
                                        "N/A"
                                      }
                                    </td>
                                    <td>{item[39]}</td>
                                    <td>
                                      {item[40] ? 
                                        <Link
                                          to={item[40]}
                                          target="_blank"
                                          className="select"
                                        >
                                          View
                                        </Link> : 
                                        'N/A'}
                                    </td>
                                    <td>
                                      { item[41] ?
                                        <Link
                                          to={item[41]}
                                          target="_blank"
                                          className="select"
                                        >
                                          View
                                        </Link> :
                                        'N/A'}
                                 
                                    </td>
                                    <td>{item[42]}</td>
                                    <td>{item[43]}</td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      )}
                    </table>
                    {totalPages > 1 && (
                      <ResponsivePagination
                        current={currentPage}
                        total={totalPages}
                        onPageChange={(page) => {
                          setCurrentPage(page);
                          getReportData(filterData, page);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
