import React, { useEffect, useState } from "react";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../loader/CircleLoade";
import { Link } from "react-router-dom";
import "./reportstyle.css";

const QrTransReport = () => {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getReportData(
      {
        mobile: "",
        status: "",
        startDate: "",
        endDate: "",
      },
      1
    );
  }, []);

  const searchFormik = useFormik({
    initialValues: {
      mobile: "",
      status: "",
      startDate: "",
      endDate: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.mobile) {
        if (data.mobile.length < 10) {
          errors.mobile = "Mobile must be in 10 digits.";
        }
      }
      if (data.startDate === "" && data.endDate !== "") {
        errors.startDate = "Please select date";
      }
      if (data.startDate !== "" && data.endDate === "") {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "End date should be greater than start date";
      }
      if (data.startDate && data.endDate) {
        const maxEndDate = new Date(data.startDate);
        maxEndDate.setDate(maxEndDate.getDate() + 30); // Adjust the range as needed (e.g., 31 days)

        if (data.endDate > maxEndDate) {
          errors.endDate =
            "End date should be within 31 days from the start date.";
        }
      }
      return errors;
    },
    onSubmit: (data) => {
      setCurrentPage(1);
      getReportData(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const getReportData = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      // const searchMobile = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      // const searchStatus = data.status === "" ? "" : `&status=${data.status}`;

      const searchMobile =
        data?.mobile !== undefined && data?.mobile !== ""
          ? `&mobile=${data?.mobile}`
          : "";
      const searchStatus =
        data?.status !== undefined && data?.status !== ""
          ? `&status=${data?.status}`
          : "";

      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      await axios
        .get(
          `${Constants.baseUrl}reports/qrTrans/list?userType=MEMBER${searchMobile}${searchStatus}${stDate}${endDate}&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${pageNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setReportData(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setReportData(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const downloadReport = (data) => {
    setDownloadLoading(true);
    try {
      const searchMobile = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      const searchStatus = data.status === "" ? "" : `&status=${data.status}`;
      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      axios
        .get(
          `${Constants.baseUrl}reports/download/qrTransreport?userType=MEMBER${searchMobile}${searchStatus}${stDate}${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
            responseType: "blob", // Set the responseType to 'blob'
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setDownloadLoading(false);
            const fileData = new Blob([res.data], { type: "text/csv" });
            const sixDigitRandomNo = Math.floor(
              100000 + Math.random() * 900000
            );
            saveAs(fileData, `qrtrans_report_${sixDigitRandomNo}.csv`);
          } else {
            setDownloadLoading(false);
            swal({
              text: res.data.msg,
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  };

  const areAllValuesBlank = () => {
    const { mobile, status, startDate, endDate } = searchFormik.values;
    return !mobile && !status && !startDate && !endDate;
  };
  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section class="page-content--bge55">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <h3 class="head1">QR Trans Report</h3>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <form onSubmit={searchFormik.handleSubmit}>
                <div class="row">
                  <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                    <div class="row">
                      <div class="col-md-3 col-sm-12 col-xs-12">
                        <div class="form-group">
                          <input
                            class="form-control reset"
                            type="text"
                            placeholder="Mobile No."
                            id="mobile"
                            value={searchFormik.values.mobile}
                            onChange={(e) => {
                              // Allow only numeric input
                              const numericValue = e.target.value.replace(
                                /\D/g,
                                ""
                              );
                              searchFormik.handleChange({
                                target: {
                                  name: "mobile",
                                  value: numericValue,
                                },
                              });
                            }}
                          />
                          {getSearchFormErrorMessage("mobile")}
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <DatePicker
                          id="startDate"
                          selected={searchFormik.values.startDate}
                          onChange={(date) =>
                            searchFormik.setFieldValue("startDate", date)
                          }
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          showYearDropdown
                          showMonthDropdown
                          autoComplete="off"
                          scrollableYearDropdown
                          maxDate={new Date()}
                          onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                          onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                          onClick={(e) => e.preventDefault()} // Preven
                          placeholderText="From date"
                        />
                        {getSearchFormErrorMessage("startDate")}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <DatePicker
                          id="endDate"
                          selected={searchFormik.values.endDate}
                          onChange={(date) =>
                            searchFormik.setFieldValue("endDate", date)
                          }
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          showYearDropdown
                          showMonthDropdown
                          autoComplete="off"
                          scrollableYearDropdown
                          maxDate={new Date()}
                          onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                          onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                          onClick={(e) => e.preventDefault()} // Preven
                          placeholderText="To date"
                        />
                        {getSearchFormErrorMessage("endDate")}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <select
                          className="form-control"
                          id="status"
                          value={searchFormik.values.status}
                          onChange={searchFormik.handleChange}
                        >
                          <option value="">Select Status</option>
                          <option value="SUCCESS">Success</option>
                          <option value="ERROR">Error</option>
                        </select>
                        {getSearchFormErrorMessage("status")}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <div class="d-flex justify-content-between">
                      <button
                        type="submit"
                        class="btn btn-primary report-submit-button"
                        //disabled={areAllValuesBlank()}
                      >
                        Search
                      </button>

                      <button
                        type="button"
                        className="btn btn-primary report-submit-button"
                        onClick={() => {
                          setFilterData({});
                          setCurrentPage(1);
                          searchFormik.resetForm();
                          getReportData(
                            {
                              mobile: "",
                              status: "",
                              startDate: "",
                              endDate: "",
                            },
                            1
                          );
                        }}
                      >
                        Reset
                      </button>

                      {reportData === null ? (
                        ""
                      ) : (
                        <Link
                          to="#"
                          class={`btn btn-primary report-submit-button mx-5 ${
                            downloadLoading ? "btn-opacity" : ""
                          }`}
                          onClick={() => downloadReport(searchFormik.values)}
                        >
                          {downloadLoading ? (
                            <span>Exporting ...</span>
                          ) : (
                            " Export"
                          )}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </form>
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive table--no-card">
                    <table class="table table-borderless table-striped table-earning">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>scanUniqueID</th>
                          <th>memberUniqueID</th>
                          <th>plumberName</th>
                          <th>mobile</th>
                          <th>zone</th>
                          <th>userWorkState</th>
                          <th>userBranch</th>
                          <th>userWorkDistrict</th>
                          <th>userWorkCity</th>
                          <th>Pincode</th>
                          <th>fsName</th>
                          <th>fsCode</th>
                          <th>DateofScan</th>
                          <th>monthOfScan</th>
                          <th>productName</th>
                          <th>productCategory</th>
                          <th>groupDetails</th>
                          <th>productSapCode</th>
                          <th>liter</th>
                          <th>qrDetails</th>
                          <th>pointsEarned</th>
                          <th>status</th>
                          <th>Reason</th>
                          {/* <th>FS Name</th> */}
                          <th>FS Mobile</th>
                          {/* <th>retailerMobile</th>
                          <th>retailerName</th>
                          <th>retailerRtCode</th>
                          <th>distributerCode</th>
                          <th>distributerName</th>
                          <th>distributerMobile</th> */}
                        </tr>
                      </thead>
                      {reportData === null ? (
                        <tbody>
                          <tr>
                            <td
                              colSpan="29"
                              style={{ textAlign: "center", fontSize: "16px" }}
                            >
                              No record found!
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {reportData &&
                            reportData.map((item, index) => {
                              const serialNumber =
                                (currentPage - 1) * limit + index + 1;
                              return (
                                <>
                                  <tr>
                                    <td>{serialNumber}</td>
                                    <td>{item[0]}</td>
                                    <td>{item[1]}</td>
                                    <td>{item[2]}</td>
                                    <td>{item[3]}</td>
                                    <td>{item[4]}</td>
                                    <td>{item[5]}</td>
                                    <td>{item[6]}</td>
                                    <td>{item[7]}</td>
                                    <td>{item[8]}</td>
                                    <td>{item[9]}</td>
                                    <td>{item[10]}</td>
                                    <td>{item[22]}</td>
                                    <td>{item[11]}</td>
                                    <td>{item[12]}</td>
                                    {/* <td>
                                      {new Date(item[12]).toLocaleDateString(
                                        "en-US"
                                      )}
                                    </td> */}
                                    <td>{item[13]}</td>
                                    <td>{item[14]}</td>
                                    <td>{item[15]}</td>
                                    <td>{item[16]}</td>
                                    <td>{item[17]}</td>
                                    <td>{item[18]}</td>
                                    <td>{item[19]}</td>
                                    <td>{item[20]}</td>
                                    <td>{item[21]}</td>
                                    {/* <td>{item[22]}</td> */}
                                    <td>{item[23]}</td>
                                    {/* <td>
                                      {new Date(item[21]).toLocaleDateString(
                                        "en-US"
                                      )}
                                    </td> */}
                                    {/* <td>{item[22]}</td>
                                    <td>{item[23]}</td>
                                    <td>{item[24]}</td>
                                    <td>{item[25]}</td>
                                    <td>{item[26]}</td>
                                    <td>{item[27]}</td> */}
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      )}
                    </table>
                    {totalPages > 1 && (
                      <ResponsivePagination
                        current={currentPage}
                        total={totalPages}
                        onPageChange={(page) => {
                          setCurrentPage(page);
                          getReportData(filterData, page);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default QrTransReport;
